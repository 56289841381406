import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { Config } from './config';


const AUTH0_DOMAIN = Config.auth0Domain as string;
const AUTO0_CLIENTID = Config.auth0ClientId as string;
const AUTH0_AUDIENCE = Config.auth0Audience as string;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTO0_CLIENTID}
    audience={AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
