import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();


  return(
      <div className="mt-10 ml-5">
        <button className="bg-gray-100 rounded-md border p-2" onClick={() => loginWithRedirect()}>Login to get your test token</button>
      </div>
  )
};

export default LoginButton;