import React from "react";
import LoginButton from "./Login";
import Profile from "./Profile";
import { useAuth0 } from "@auth0/auth0-react";


const App = () => {
  const { error, isAuthenticated, isLoading } = useAuth0();
  
  if(!isLoading && !isAuthenticated ){
    return <LoginButton />
  }

  if(error) {
    return <div>An error occured try to delete your cache</div>
  }
  
  return <Profile />
};

export default App;