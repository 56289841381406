import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Logout from "./Logout";
import LoginButton from "./Login";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const Profile = () => {
  const { error, user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  }

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!token) {
        getToken();
    }
  }, []);

  const getToken = async () => {
      const tokenValue = await getAccessTokenSilently();
      setToken(tokenValue)
  }

  if(error) {
      return <LoginButton />
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if(!isAuthenticated){
      return <LoginButton />
  }

  return (
    <div className="mt-5 mx-80 bg-slate-700 text-white rounded-xl p-12 ">
      <div>
        <p>{user!.email}</p>
        <CopyToClipboard text={token} onCopy={onCopy}>
        <div className="mt-5 bg-slate-800 p-5 cursor-pointer">
            {!copied &&
            <div>
              <h2 className="text-xl font-bold"> Access token </h2>
              <p className="text-xs break-all">
                  {token != "" ? token : "Loading your token..."}
              </p>
            </div>
            }
            {copied &&
            <p>Copied!</p>

            }
        </div>
        </CopyToClipboard>
      </div>
      {/* <div className="mt-5 text-sm text-amber-400">
        <a href="https://test-api.nilos.io/api-docs" target="_blank">Click to test the API swagger</a>
      </div> */}
      <div className="mt-10 text-slate-700">
          <Logout />
      </div>
    </div>
  );
};

export default Profile;